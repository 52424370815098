import React, {ChangeEvent, FocusEvent, KeyboardEvent} from 'react';
import CardHolder from './CardHolder';

import {CreditCard, CreditCardBuilder, CardValidator, PaymentNetwork, PaymentNetworkFactory} from "./CardNumber";

export interface CardNumberInputProps {
    onSubmit: (cc: CreditCard, ch: CardHolder) => void;
};

export interface CardNumberInputState {
    /**
     * An array of four strings representing the four groups of digits in a card number.
     */
    fields: Array<string>,
    expiryMonth: string,
    expiryYear: string,
    securityCode: string,
    cardholderName: string,
    phone: string,
    countryCode: string

};

class CardNumberInput extends React.Component<CardNumberInputProps, CardNumberInputState> {

    constructor(props: CardNumberInputProps ) {
        super(props);

        // this.state = {
        //     fields: ["4032", "0315", "9562", "2852" ],
        //     expiryMonth: "3",
        //     expiryYear: "24",
        //     securityCode: "432",
        //     cardholderName: "Ola Nordmann",
        //     phone: "12345678"
        //     countryCode: "47"
        // };

        // this.state = {
        //     fields: ["4321", "1141", "5636", "3007"],
        //     expiryMonth: "3",
        //     expiryYear: "26",
        //     securityCode: "493",
        //     cardholderName: "Test ABC",
        //     phone: "12345678",
        //     countryCode: "47"
        // };

       // this.state = {
       //     fields: ["5204", "7305", "4100", "1066" ],
       //      expiryMonth: "9",
       //      expiryYear: "26",
       //      securityCode: "181",
       //      cardholderName: "Ola Nordmann",
       //      phone: "12345678",
       //      countryCode: "47"
       //  };

       this.state = {
            fields: ["", "", "", "" ],
            expiryMonth: "",
            expiryYear: "",
            securityCode: "",
            cardholderName: "",
            phone: "",
             countryCode: "47"
        };

    }

    public get cardNumber() {
        return this.state.fields.join("");
    }


    paymentNetwork() {
        const pmi = PaymentNetworkFactory.fromString(this.cardNumber);

        let pmn_string = "unknown";

        if ( pmi === PaymentNetwork.VISA ) {
            pmn_string = "visa";
        } else if ( pmi === PaymentNetwork.MASTERCARD ) {
            pmn_string = "mastercard";
        }

        return pmn_string;
    }


    validateInputs() {

        return CardValidator.validateCardNumber(this.cardNumber)
            && CardValidator.validateSecurityCode(this.state.securityCode)
            && CardValidator.validateExpiry([Number(this.state.expiryMonth), Number(this.state.expiryYear) + 2000]);
    }

    calculateInputClasses() {
        let res : Array<string> = [];

        res.push("payment-provider-" + this.paymentNetwork());

        const detailsFilled = this.cardNumber.length > 14
                && this.state.expiryYear.length >= 2
                && this.state.expiryMonth.length >= 1
                && this.state.securityCode.length >= 3
                && this.state.countryCode.length > 0
                && this.state.phone.length > 0;

        if ( detailsFilled && this.validateInputs() )
        {
            res.push("card-details-valid");
        } else if (detailsFilled) {
            res.push("card-details-invalid");
        }


        return res.join(" ");
    }

    getContainer(el: HTMLElement) : HTMLElement | null {
        let ptr : HTMLElement | null = el;


        while ( ptr != null && ! (ptr).classList.contains('cn-Container') ) {
            ptr = ptr.parentNode as HTMLElement | null;
        }

        return ptr;
    }

    /**
     * Refocuses the input elements to target the leftmost unfilled text field.
     */
    onFocus(e: FocusEvent<HTMLInputElement>) {
        // const order_attr = e.target.getAttribute('data-order');
        // const order = order_attr ? Number(order_attr) : 99;

        let adjustedTarget = e.target;

        const container = this.getContainer(adjustedTarget);

        // type X = HTMLCollectionOf<Element>;

        const siblings = container?.getElementsByClassName('cn-Input--cardnumber');


        // No siblings to retarget, bail out!
        if ( ! siblings ) return;


        let myIndex = 0;
        for ( let i = 0; i < siblings.length; i++ ) {
            if ( siblings[i] === e.target ) {
                myIndex = i;
            }
        }

        for ( let idx = myIndex; idx >= 0; idx-- ) {
            let candidate = siblings[idx] as HTMLInputElement;

            if ( candidate.value.length > 0 ) break;

            adjustedTarget = candidate;
        }


        if ( adjustedTarget )  {
            adjustedTarget.focus();
            adjustedTarget.select();
        }
    }

    /**
     * Handles intents such as `Backspace`, `ArrowLeft` and `ArrowRight` to
     * swap between input fields.
     */
    refocus(e: KeyboardEvent<HTMLInputElement>) {

        e.stopPropagation();

        const target = e.target as HTMLInputElement;

        const cursorPosition = target?.selectionStart;
        const selectionEnd   = target?.selectionEnd;

        const isSelection = cursorPosition !== selectionEnd;

        const key         = e?.key;
        const isBackspace = key === "Backspace";
        const isLeftKey   = key === "ArrowLeft";
        const isRightKey  = key === "ArrowRight";


        if ( !isSelection && ( isBackspace || isLeftKey ) && cursorPosition === 0 ) {
            this.focusPrev(target);
            e.preventDefault();
        } else if ( isRightKey && cursorPosition === target.value.length ) {
            this.focusNext(target);
            e.preventDefault();
        }
    }


    focusRelative(el: HTMLInputElement, idx: number ) {
        let parent = this.getContainer(el);
        let order = el.getAttribute('data-order');

        if ( ! order ) { return; }

        let offset_index = Number(order) + idx;

        let relativeInput = parent?.getElementsByClassName('cn-Input')[offset_index] as HTMLInputElement | undefined;

        relativeInput?.focus();
    }

    focusNext(el: HTMLInputElement) {
        this.focusRelative(el, 1);
    }

    focusPrev(el: HTMLInputElement) {
        this.focusRelative(el, -1);
    }

    handleCardNumber(e: ChangeEvent<HTMLInputElement>, fieldIdx: number ) {
        e.stopPropagation();
        e.preventDefault();

        const target = e.target;

        let field_array = this.state.fields.slice();

        let sanitized = target.value.slice();
        sanitized = sanitized.split("").filter((v) => {
            const cc0 = "0".charCodeAt(0) as number;
            const cc9 = "9".charCodeAt(0) as number;
            let cc = v.charCodeAt(0) as number;

            return cc <= cc9 && cc >= cc0;
        }).join("");


        field_array[fieldIdx] = sanitized;


        this.setState({
            ...this.state,
            fields: field_array
        });


        if ( target.value.length >= 4 ) {
            this.focusNext(target);
        }
    }


    handleMonth(e: ChangeEvent<HTMLInputElement>) {
        e.stopPropagation();
        e.preventDefault();

        const target = e.target;

        let s_month = target.value;
        let month = Number(target.value);

        if ( s_month.length > 0 ) {
            if ( Number(s_month.substring(0,1)) > 1 ) {
                this.focusNext(target);
            }
        }

        if ( s_month.length >= 3 ) {
            this.focusNext(target);
            return;
        }

        if ( month > 12 ) {
                this.focusNext(target);
                return;
        }

        if ( s_month.length >= 2 ) {
                this.focusNext(target);
        }

        let repr = String(month) === "0" ? "" : String(month);

        this.setState({
            ...this.state,
            expiryMonth: repr
        });

    }

    handleYear(e: ChangeEvent<HTMLInputElement>) {
        e.stopPropagation();
        e.preventDefault();

        const target = e.target;

        if ( target.value.length > 2 ) {
            this.focusNext(target); return;
        }

        let year = Number(target.value);

        let repr = String(year);
        if ( repr === "0" ) repr = "";

        this.setState({
            ...this.state,
            expiryYear: repr
        });

        if ( target.value.length === 2 ) { this.focusNext(target); }

    }

    handleSecurityCode(e: ChangeEvent<HTMLInputElement>) {
        e.stopPropagation();
        e.preventDefault();

        const target = e.target;

        if ( target.value.length > 4 ) return;

        this.setState({
            ...this.state,
            securityCode: target.value
        });

    }

    handleSubmit(e : React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();


        let newCC = (function(self) {

            let CCB = new CreditCardBuilder()
                .setPan(self.cardNumber)
                .setCvc(self.state.securityCode)
                .setExpiryMonth(Number(self.state.expiryMonth))
                .setExpiryYear(Number(self.state.expiryYear) + 2000)
                .setCardholderName(self.state.cardholderName);


            return CCB.build();
        })(this);

        let cardholder = new CardHolder(this.state.cardholderName);
        cardholder.countryCode = Number(this.state.countryCode);
        cardholder.phoneNumber = this.state.phone;

        // let newCC = new CreditCard(this.cardNumber, this.state.securityCode, Number(this.state.expiryMonth), Number(this.state.expiryYear) + 2000, this.state.cardholderName);


        if ( CardValidator.validateCard(newCC) ) {
            this.props.onSubmit(newCC, cardholder);
        }

    }

    handleCardholder( e: ChangeEvent<HTMLInputElement> ) {
        e.preventDefault();
        e.stopPropagation();


        this.setState({
            ...this.state,
            cardholderName: e.target.value
        });
    }

    handlePhone( e: ChangeEvent<HTMLInputElement> ) {
        e.preventDefault();
        e.stopPropagation();

        if ( isNaN(Number(e.target.value)) ) return;
        if ( e.target.value.length > 12 ) return;


        this.setState({
            ...this.state,
            phone: e.target.value
        });
    }

    handleCountryCode(  e: ChangeEvent<HTMLInputElement> ) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            ...this.state,
            countryCode: e.target.value
        });

    }

    checked: boolean = false;

    render() {

        let inputFields = [];

        // Generate the four input fields
        for ( let i = 0; i < 4; i++ ) {
            inputFields.push((
            <input type="text"
                className="cn-Input cn-Input--cardnumber"
                placeholder="0000"
                value={this.state.fields[i]}
                onChange={(e) => this.handleCardNumber(e, i)}
                onKeyDown={(e) => this.refocus(e)}
				onFocus={(e) => this.onFocus(e)}
                data-order={i+2}
                key={i+2}
                maxLength={4} />
            ));
        }

        return (
            <div>
            <form action="#" onSubmit={(e) => {this.handleSubmit(e)}}>
            <div className={"cn-Container " + this.calculateInputClasses()}>


            <div className="cn-InputWrapper">
                <div className="cn-InputWrapper_Descriptor">Card Holder Name</div>

                <div className="cn-InputWrapper_Fields cn-InputWrapper_Fields--name">
                    <input type="text"
                    className="cn-Input cn-Input--name"
                    placeholder="Ola Nordmann"
                    value={this.state.cardholderName}
                    onChange={(e) => this.handleCardholder(e)}
                    onKeyDown={(e) => this.refocus(e)}
                    data-order={0} />
                </div>
            </div>


            <div className="cn-Row">

            <div className="cn-InputWrapper cn-InputWrapper--ccode">
                <div className="cn-InputWrapper_Descriptor">Country Code</div>
                <div className="cn-InputWrapper_Fields">
                <div className="cn-InputWrapper_Pretext">
                +
                </div>

                <input type="text" className="cn-Input cn-Input--ccode"
                    placeholder="47"
                    value={this.state.countryCode}
                    onChange={(e) => this.handleCountryCode(e)}
                    data-order={0} />
                </div>

            </div>

            <div className="cn-InputWrapper">

                <div className="cn-InputWrapper_Descriptor">Phone Number</div>

                <div className="cn-InputWrapper_Fields">

                <input type="text"
                    className="cn-Input cn-Input--phone"
                    placeholder="Phone Number"
                    value={this.state.phone}
                    onChange={(e) => this.handlePhone(e)}
                    data-order={1} />

                </div>

                </div>
            </div>


            <div className="cn-InputWrapper cn-InputWrapper--pan">
                <div className="cn-InputWrapper_Descriptor">Card Number</div>

                <div className="cn-InputWrapper_Fields">
                    <div className="cn-InputWrapper_Pretext cn-CardType">

                    </div>
                    {inputFields}
                </div>
            </div>

            <div className="cn-Row cn-Row--between">

            <div className="cn-InputWrapper cn-InputWrapper--expiry">
            <div className="cn-InputWrapper_Descriptor">Expiry</div>
            <div className="cn-InputWrapper_Fields">
            <input type="text"
                className="cn-Input cn-Input--month"
                value={this.state.expiryMonth}
                data-order={5}
                onKeyDown={(e) => this.refocus(e)}
                onChange={(e) => {this.handleMonth(e)}}
                placeholder={String(new Date(Date.now()).getMonth())}
                />
            <input type="text"
                className="cn-Input cn-Input--year"
                placeholder={String(new Date(Date.now()).getFullYear()).substring(2,4)}
                value={this.state.expiryYear}
                onChange={(e) => {this.handleYear(e)}}
                onKeyDown={(e) => this.refocus(e)}
                data-order={6}
                />
            </div>
            </div>

            <div className="cn-InputWrapper cn-InputWrapper--cvc">
                <div className="cn-InputWrapper_Descriptor">
                    CVC
                </div>
                <div className="cn-InputWrapper_Fields">
                    <input type="text"
                    className="cn-Input cn-Input--cvc"
                    placeholder="123"
                    value={this.state.securityCode}
                    onChange={(e) => {this.handleSecurityCode(e);}}
                    onKeyDown={(e) => this.refocus(e)}
                    data-order={7}
                    />


                </div>
            </div>


            </div>



            <div className="cn-InputWrapper">
                <button className="cn-Submit">Submit</button>
            </div>
            </div>
            </form>
            </div>
        );
    }


}

export default CardNumberInput;
