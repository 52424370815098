import { CreditCard } from "./CardNumber";
import IEnrollmentFlowHandler from "./IEnrollmentFlowHandler";
import {MC_API_BASE_URL} from "./Config";
import RequestFactory from "./RequestFactory";
import Fingerprint from "./Fingerprinting";
import ACSChallengeData from "./ACSChallengeData";
import CardHolder from "./CardHolder";

export default class MastercardEnrollmentFlowHandler implements IEnrollmentFlowHandler {

    private readonly creditCard : CreditCard;
    private readonly cardholder : CardHolder;

    private authenticationSuccess : boolean = false;

    private _cardRef: string;

    getIdentifier() : string {
        return this._cardRef;
    }

    private fingerprintingParameters : {};

    private areq_response : any;

    constructor(cc: CreditCard, cardholder: CardHolder) {
        this.creditCard = cc;
        this.cardholder = cardholder;
        this._cardRef = "";
        this.fingerprintingParameters = {};
    }

    async prepare(): Promise<boolean> {

        let data;

        try {
            data = await MCEnrollmentAPIHandler.doPreparation(this.creditCard, this.cardholder);
        } catch ( e: any ) {
            if ( e instanceof TypeError) {
                return Promise.reject("Failed to connect to the server");
            }
                return Promise.reject("Unknown error occured");
        }



        let json = await data.json();

        if ( json.hasOwnProperty("threeDsMethodUrl") ) {
            this._cardRef = json.cardReference;
            await this.doFingerprinting(json.threeDsMethodUrl, json.threeDSMethodData);
            return Promise.resolve(true);
        } else {
            return Promise.reject(json?.message);
        }
    }

    fingerprintCallback(params: {}) {
        this.fingerprintingParameters = params;
    }

    doFingerprinting(methodURL: string, methodData: string) : Promise<any> {

        let fingerprintPromise : Promise<any> = new Promise((resolve) => {
            let fingerprinter = new Fingerprint(
                (params) => {
                    this.fingerprintCallback(params);
                    resolve(true);
                }
            );

            fingerprinter.doFingerprint(methodURL, methodData);
        });



        return fingerprintPromise;
    }

    async authenticate(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let data = await MCEnrollmentAPIHandler.startAuthentication(
                this.fingerprintingParameters,
                this.creditCard,
                this._cardRef);

            data.json().then((data) => {
                this.areq_response = data;
                resolve(data);
            }).catch((data) => {
                reject(data)
            });
        });
    }

    needsChallenge(): boolean {
        return true;
    }

    challenge(): ACSChallengeData {
        let data = this.areq_response;
        return new ACSChallengeData(true, data.acsUrl, data.encodedCReq);
    }

    async verify(): Promise<any> {

        return new Promise(async (resolve, reject) => {

            MCEnrollmentAPIHandler.verifyAuthentication(this._cardRef, this.creditCard)
            .then((data) => {

                console.log(data);

                data.json().then((json) => {

                    if ( json.status == "AUTHENTICATED" ) {
                        this.authenticationSuccess = true;
                        resolve(true);
                    }

                    reject("Failed to authenticate MasterCard.");
                });
            });

        });

    }

    isAuthenticated() : boolean {
        return this.authenticationSuccess;
    }

}



class MCEnrollmentAPIHandler {
    static BASE_URL = MC_API_BASE_URL;


    static async startAuthentication(
        params: {},
        card: CreditCard,
        cardReference: string
    ) : Promise<Response> {
        let request_body = {
            params: { ...params},
            cardDetails: card.toJSON(),
            cardReference: cardReference
        };

        let request = RequestFactory.createRequest(request_body, "POST");

        return fetch(this.BASE_URL + "/mc/api/start-3ds-authentication", request);

    }

    static async verifyAuthentication(
        cardReference: string,
        _cardDetails: CreditCard
    ) : Promise<Response> {
        let request_body = {
            cardRef: cardReference,
            cardDetails: _cardDetails.toJSON()
        };

        let request = RequestFactory.createRequest(request_body, "POST");

        return fetch(this.BASE_URL + "/mc/api/verify-authentication", request);

    }


    static async doPreparation(
        cc: CreditCard,
        cardholder: CardHolder
    ) : Promise<Response> {
        let request = RequestFactory.createRequest(
            {
                "card": cc.toJSON(),
                "cardholder": cardholder.toJSON()
            }
        );

        return fetch(this.BASE_URL + "/mc/api/create-consents", request);
    }

};

export class MCPrepareJWTRequest {
    static BASE_URL = MC_API_BASE_URL;

    static getJWT() {
        let request = RequestFactory.createRequest(
            {}
        );
        return fetch(this.BASE_URL + "/mc/api/start-consent-flow", request);
    }
}

