export default class RequestFactory {
    static createRequest( body: {}, method : string = "POST" ) : RequestInit
    {
        let request : RequestInit = {};

        request.mode = "cors";
        request.body = JSON.stringify(body);
        request.method = method;
        request.headers = new Headers();

        request.headers.set("Content-Type", "application/json");


        return request;
    }
};





