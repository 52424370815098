import IFrameFormInjector from "./IFrameFormInjector";

export default class ThreeDS_Fingerprint {

    private callback : Function;

    constructor(callback: ({}) => void) {
        this.callback = callback;
    }


    // This listener receives messages posted to the window. When it gets the
    // threeds-method-notification message it sends the required data to the
    // sserver to start authentication.
    fingerprintCompleteListener(m: MessageEvent) {
        if (m.data?.type === 'threeds-method-notification') {
            console.log('fingerprintCompleteListener called');
            this.proceedAfterFingerprint('complete');
        }
    };

    // Next step after fingerprinting (either when it is completed or it was not needed)
    proceedAfterFingerprint(fingerprintStatus : string) {
        // These parameters control what the ACS challenge window will look like. For simplicity we just
        // hard code some
        const params = {
            fingerprintStatus: fingerprintStatus,
            challengeWindowSize: '04', // 600x400
            browserAcceptHeader: 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8',
            browserColorDepth: window.screen.colorDepth,
            browserJavaEnabled: true,
            browserLanguage: navigator.language,
            browserScreenHeight: window.screen.height,
            browserScreenWidth: window.screen.width,
            browserTZ: new Date().getTimezoneOffset(),
            browserUserAgent: window.navigator.userAgent,
        }

        this.callback(params);
    };

    // Open fingerprint iframe (hidden). It collects some browser information and posts it to the 3DS ACS
    public doFingerprint(threeDsMethodUrl: string, threeDSMethodData: string) {
        if (threeDsMethodUrl) {

            IFrameFormInjector.anonymousIFrame(threeDsMethodUrl, { threeDSMethodData: threeDSMethodData }, this.fingerprintCompleteListener.bind(this), "3DS Fingerprinting");

        } else {
            // The ACS doesn't require any fingerprinting. Proceed.
            this.proceedAfterFingerprint('unavailable');
        }
    };

}
