export default class ACSChallengeData {
    required: boolean;
    methodURL: string;
    methodData: string

    constructor(_required: boolean, _methodURL: string, _methodData: string) {
        this.required = _required;
        this.methodURL  =  _methodURL;
        this.methodData =  _methodData;
    }

};
