import React, { useRef } from 'react';
import receipts_logo from './receipts_logo_notext.svg';

interface HeaderState { };


interface HeaderProps { };

export default class Header extends React.Component<HeaderProps, HeaderState> {


    constructor(props: HeaderProps) {
        super(props);

    }

    render() {
        return (
            <header className="hed-Header">
                <div className="hed-Header_LeftContainer">
                    <img className="hed-Header_Logo" src={receipts_logo} alt="" />
                </div>
                <div className="hed-Header_RightContainer">
                    <h1 className="hed-Header_Title">Card<br/>Enrollment</h1>
                </div>
            </header>
        );
    }


}
