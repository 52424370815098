import React, { PropsWithChildren, useRef } from 'react';
import receipts_logo from './receipts_logo.svg';
import check from "./check.svg"

interface SuccessBoxState { };


type TSuccessBoxProps =  { }

export default class SuccessBox extends React.Component<PropsWithChildren<TSuccessBoxProps>, SuccessBoxState> {


    constructor(props: PropsWithChildren<TSuccessBoxProps>) {
        super(props);

    }

    render() {
        return (
            <div className="msg msg--success">
                <div className="msg-Checkmark">
                    <img src={check} width="20"/>
                </div>
                {this.props.children}
            </div>
        );
    }


}
