import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";

type ErrorMessageProps = {message: string};


export default function ErrorMessage(props: ErrorMessageProps) {

    return (
        <div className="msg-Message msg-Message--error">

            <div className="msg-Message_Icon">
                <FontAwesomeIcon icon={faFlag}/>
            </div>

            <div className="msg-Message_Leader">
            Oops!
            </div>

            <div className="msg-Message_Content">
                {props.message}
            </div>
        </div>
    );

}
