import ACSChallengeData from "./ACSChallengeData";

/**
 * The interface describing the necessary steps in handling authentication.
 */
export default interface IEnrollmentFlowHandler {

    getIdentifier() : string;

    prepare() : Promise<any>;

    authenticate() : Promise<any>;

    challenge() : ACSChallengeData;

    needsChallenge(): boolean;

    verify() : Promise<any>;

    isAuthenticated(): boolean;

}



export class NoOpEnrollmentFlowHandler implements IEnrollmentFlowHandler {
    getIdentifier(): string {
        return "INVALID";
    }
    prepare(): Promise<boolean> {
        return Promise.reject("Card type not supported");
    }
    authenticate(): Promise<boolean> {
        return Promise.reject("Card type not supported");
    }
    needsChallenge(): boolean {
        return false;
    }
    challenge(): ACSChallengeData {
        throw "Not implemented";
    }
    verify(): Promise<boolean> {
        return Promise.reject("Card type not supported");
    }

    isAuthenticated(): boolean {
        throw new Error("Method not implemented.");
    }

}


