import { CreditCard, PaymentNetwork, PaymentNetworkFactory } from "./CardNumber";
import MastercardEnrollmentFlowHandler from "./MastercardEnrollmentFlowHandler";
import IEnrollmentFlowHandler, {NoOpEnrollmentFlowHandler} from "./IEnrollmentFlowHandler";
import VisaEnrollmentFlowHandler from "./VisaEnrollmentFlowHandler";
import CardHolder from "./CardHolder";

import {VISA_ENABLED, MASTERCARD_ENABLED} from "./Config";

export default class EnrollmentFlowHandlerFactory {
    static create(cc?: CreditCard, cardholder?: CardHolder) : IEnrollmentFlowHandler {

        if ( ! cc || ! cardholder ) return new NoOpEnrollmentFlowHandler();

        let paymentNetwork = PaymentNetworkFactory.fromCreditCard(cc);

       if ( paymentNetwork === PaymentNetwork.VISA && VISA_ENABLED ) {
               return new VisaEnrollmentFlowHandler(cc, cardholder);
       }  else if ( paymentNetwork === PaymentNetwork.MASTERCARD && MASTERCARD_ENABLED ) {
                return new MastercardEnrollmentFlowHandler(cc, cardholder);
        } else {
                return new NoOpEnrollmentFlowHandler();
        }
    }
}
