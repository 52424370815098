import React, { useRef } from 'react';
import ACSChallengeData from './ACSChallengeData';
import IFrameFormInjector from './IFrameFormInjector';

// import Challenge

interface ACSChallengeState {

};


interface ACSChallengeProps {
    acsData : ACSChallengeData,
    callback: ({}) => void
};

export default class ACSChallenge extends React.Component<ACSChallengeProps, ACSChallengeState> {

    private _didMount = false;

    challengeCompleteListener(m: MessageEvent) {
        if (m.data.type === 'threeds-challenge-notification') {
            this.challengeComplete();
        }
    }

    challengeComplete() {
        this.props.callback({});
    }

    componentDidMount() {
        if ( this._didMount ) return;
        this._didMount = true;

        const url = this.props.acsData.methodURL;
        const body = { creq: this.props.acsData?.methodData };
        const callback = this.challengeCompleteListener.bind(this);

        const iframe = document.getElementById("3ds-challenge") as HTMLIFrameElement;

        IFrameFormInjector.injectIFrame(url, body, callback, iframe);

    }

    constructor(props: ACSChallengeProps) {
        super(props);

    }

    render() {
        return (
        <div className="acc-Challenge">
            <center>
            <p className="acc-Challenge_Title">ACS CHALLENGE</p>
            <iframe id="3ds-challenge" width={600} height={400} frameBorder="0" className="acc-Frame"  />
            </center>

        </div> );
    }


}
