import CardHolder from "./CardHolder";

export enum PaymentNetwork {
    NONE,
    VISA,
    MASTERCARD
};

export const PaymentNetworkNames = (() => {
    let res = [];
    res[Number(PaymentNetwork.NONE)] = "Unknown";
    res[Number(PaymentNetwork.VISA)] = "Visa";
    res[Number(PaymentNetwork.MASTERCARD)] = "Mastercard";

    return res;
})();


export class CreditCardBuilder
{
    private pan : string = "";
    private cvc : string = "";
    private expiryMonth : number = 1;
    private expiryYear : number = 2023;
    public cardholderName : string = "";

    constructor() {

    }

    setPan(pan: string) { this.pan = pan; return this; }
    setCvc(cvc: string) { this.cvc = cvc; return this; }
    setExpiryMonth(month: number) { this.expiryMonth = month; return this; }
    setExpiryYear(year: number) { this.expiryYear = year; return this; }
    setCardholderName(name: string) { this.cardholderName = name; return this; }

    build() {
        let result = new CreditCard(
            this.pan, this.cvc, this.expiryMonth, this.expiryYear,
            this.cardholderName );

        return result;
    }
};

export class CreditCard
{
    private pan : string = "";
    private cvc: string = "";
    private expiryMonth: number = 1;
    private expiryYear: number = 2023;
    private cardholder_: string;

    constructor(
        cn: string,
        securityCode: string,
        expiryMonth: number,
        expiryYear: number,
        cardholder: string,
       ) {

        this.cardholder_ = cardholder;

        this.cardNumber = cn;
        this.securityCode = securityCode;
        this.expiry = [expiryMonth, expiryYear];
    }

    toJSON() : any {
        return {
            cardholderName: this.cardholder_,
            cardNumber: this.cardNumber,
            securityCode: this.securityCode,
            expirationMonth: this.expiryMonth,
            expirationYear: this.expiryYear
        };
    }

    public static sanitizeCardNumber (cn: string) : string {
        return cn.split("").filter((val) => {
            const cp0 = "0".codePointAt(0) as number;
            const cp9 = "9".codePointAt(0) as number;
            let codePoint = val.codePointAt(0) as number;

            return codePoint <= cp9 && codePoint >= cp0;
        }).join("");
    }

    public get cardNumber() {
        return this.pan;
    }

    public set cardNumber(cn: string) {
        let sanitizedNumber = CreditCard.sanitizeCardNumber(cn);
        this.pan = sanitizedNumber;
    }

    public get securityCode(): string {
        return this.cvc;
    }

    public set securityCode(value: string) {
        this.cvc = value;
    }

    public get expiry() {
        return [this.expiryMonth, this.expiryYear];
    }

    public set expiry(expiry: Array<number>) {

        if ( expiry.length < 2 ) {
            throw Error("Card expiry must consist of a month and a year!");
        }

        const month = expiry[0];
        const year = expiry[1];

        this.expiryMonth = month;
        this.expiryYear = year;
    }

    public set cardHolder(ch: string) {
        this.cardholder_ = ch;
    }

    public get cardHolder() {
        return this.cardholder_;
    }

}

export class PaymentNetworkFactory {

    private static translation_table : Array<PaymentNetwork> = [
        PaymentNetwork.NONE,
        PaymentNetwork.NONE,
        PaymentNetwork.MASTERCARD,
        PaymentNetwork.NONE, // AmEx
        PaymentNetwork.VISA,
        PaymentNetwork.MASTERCARD,
        PaymentNetwork.NONE, // Discover
        PaymentNetwork.NONE,
        PaymentNetwork.NONE,
        PaymentNetwork.NONE
    ];

    static fromString(identifier: string) {
        const s_ID = identifier[0];
        const ID = Number(s_ID);

        return this.translation_table[ID];
    }

    static fromCreditCard(card: CreditCard) {
        return PaymentNetworkFactory.fromString(card.cardNumber);
    }
}

export class CardValidator {

    /**
     * Applies Luhn's algorithm to card numbers.
     *
     */
    static validateCardNumber(cardnum : string) : boolean {

        const cardnum_array = cardnum.split("");
        const len = cardnum_array.length;

        let multiplied_cardnum = cardnum_array.map((v:string,idx:number) => {
            const right_offset = len - idx;

            const parity = right_offset % 2 === 0;
            const multiplier = parity ? 2 : 1;

            const res = Number(v) * multiplier;

            return Math.floor(res / 10) + (res % 10);
        });

        const sum = multiplied_cardnum.reduce((prev, cur) => {
            return prev + cur;
        }, 0);

        return (sum % 10) === 0;
    }

    static validateSecurityCode( verifCode : string ) {
        const len = verifCode.length;
        return len === 3 || len === 4;
    }

    static validateExpiry( expiry: Array<number> ) {
        const expMonth = expiry[0];
        const expYear =  expiry[1];

        const validMonth = expMonth >= 1 && expMonth <= 12;
        const validYear = expYear >= (new Date(Date.now()).getFullYear());

        return validMonth && validYear;
    }


    static validateCard( cc: CreditCard ) {
        const validNumber = CardValidator.validateCardNumber(cc.cardNumber);
        const validSecurityCode =  CardValidator.validateSecurityCode(cc.securityCode);
        const validExpiry = CardValidator.validateExpiry(cc.expiry);

        const valid = validNumber && validSecurityCode &&
                validExpiry;

        return valid;
    }
}

export default CardValidator;
