export default class CardHolder {

    private firstname_: string = "";
    private lastname_ : string = "";

    private countryCode_ ?: number;
    private phoneNumber_ ?: string;


    constructor(name: string) {
        this.name = name;
    }

    toJSON() : any {
        return {
            firstName: this.firstname_,
            lastName: this.lastname_,
            phoneNumber: this.phoneNumber_,
            countryCode: this.countryCode_
        };
    };

    public get firstName() {
        return this.firstname_;
    }

    public set firstName(fname: string) {
        this.firstname_ = fname;
    }

    public get lastName() {
        return this.lastname_;
    }

    public set lastName(lname: string) {
        this.lastname_ = lname;
    }

    public get name() {
        return this.firstname_ + " " + this.lastname_;
    }

    public set name(name: string) {
        if ( name.length < 3 ) throw "Invalid cardholder name";

        let components = name.split(" ");

        let firstname = "";

        for ( let i = 0; i < components.length - 1; i++ ) {
            firstname += components[i];
        }

        this.firstname_ = firstname;

        this.lastname_ = components[components.length - 1];
    }

    public get countryCode() { return this.countryCode_ || 0; }

    public set countryCode(code: number) {
        if ( code < 1 ) throw "Invalid country code";

        this.countryCode_ = code;
    }

    public get phoneNumber() {
        return this.phoneNumber_ || "";
    }

    public set phoneNumber(phoneNo: string) {
        if ( phoneNo.length < 5 ) throw "Phone number too short!";

        this.phoneNumber_ = phoneNo;
    }
}
