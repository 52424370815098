export default class IFrameFormInjector
{

    static createFormScript(url: string, body: any) {
        let appendStatements = "";
        for ( let key of Object.keys(body) ) {
            const value = body[key];
            appendStatements += `\nform.appendChild(createInput("${key}", "${value}"));`;
        }

        const html = `<script>
            document.addEventListener("DOMContentLoaded", function () {
                var form = document.createElement("form");
                form.method = "POST";
                form.action = "${url}";
                ${appendStatements}
                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);
            });
            function createInput(name, value) {
                var result = document.createElement("input");
                result.name = name;
                result.value = value;
                return result;
            }
            </script>`;

            return html;
    }


    static anonymousIFrame(url: string, body: any, callback: (m:MessageEvent) => any, winName="") {
        const formScript = this.createFormScript(url, body);

        const iframe = document.createElement("iframe");
        iframe.id = '3ds-fingerprint';
        document.body.appendChild(iframe);
        iframe.style.display = "none";
        const win = iframe.contentWindow;
        if (win != null) {
            const doc = win.document;
            win.name = winName;
            doc.open();
            doc.write(formScript);
            doc.close();
        }
        window.addEventListener("message", callback);

    }

    static injectIFrame(url: string, body: any, callback: (m:MessageEvent) => any, iframe: HTMLIFrameElement) {
        const scriptElement = IFrameFormInjector.createFormScript(url, body);

        const iframeContentWindow = iframe.contentWindow;

        if ( ! iframeContentWindow ) return;

        iframeContentWindow.name = "Window";
        const doc = iframeContentWindow.document;

        doc.open();
        doc.write(scriptElement);
        doc.close();
        window.addEventListener("message", callback);
    }

};
