import React, { useEffect, useState } from 'react';
import './App.scss';
import CNI from "./CardNumberInput" ;
import {CreditCard} from "./CardNumber";
import ACSChallenge from "./ACSChallenge"
import IEnrollmentFlowHandler from "./IEnrollmentFlowHandler";

import EnrollmentFlowHandlerFactory from "./EnrollmentFlowHandlerFactory";
import LoadingSpinner from './LoadingSpinner';

import {v4 as uuid} from "uuid";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ConsentFlow from './ConsentFlow';
import Disclaimer from './Disclaimer';
import SuccessBox from './SuccessBox';
import Header from './Header';
import ErrorMessage from './ErrorMessage';
import CardHolder from './CardHolder';


enum STATE {
    INPUT_CARD,
    FINGERPRINT,
    AREQ, ARES,
    CREQ, CRES,
    RREQ, RRES,
    ERROR
};

function App() {

    // Key to avoid reconstructing new instances and remounting them due to conditional rendering
    const ACSChallengeKey = uuid();

    const [FSMState, setFSMState]             = useState<STATE>(STATE.INPUT_CARD);
    const [challengeData, setChallengeData]   = useState<any>({});
    const [error, setError]                   = useState<string>("");

    const [authHandler, setAuthHandler] = useState<IEnrollmentFlowHandler>(EnrollmentFlowHandlerFactory.create());


    // Use the state transition to invoke 3DS authentication.
    useEffect(() => {
        if ( FSMState === STATE.AREQ ) {
            authHandler.authenticate().then(_ => {
                if ( ! authHandler.needsChallenge() ) {
                    authHandler.verify().then((data) => {
                        console.log(data);
                        setFSMState(STATE.RRES);
                    });

                    return;
                } else {
                    setChallengeData(authHandler.challenge());
                    setFSMState(STATE.CREQ);
                }

            }).catch(err => {
                setError(err);
                setFSMState(STATE.INPUT_CARD);
            });

        } else if ( FSMState === STATE.CRES ) {
            authHandler.verify().then((data) => {
                console.log(data);
                setFSMState(STATE.RRES);
            }).catch(err => {
                console.error(err);
                setError(err);
                setFSMState(STATE.INPUT_CARD);
            });
        }
    }, [FSMState]);

    const displayErrorMessage = () => {
        if ( error && error.length > 1 ) {
            return (
                <ErrorMessage message={error}/>
           );
        } else {
            return (<></>);
        }
    };


    function postChallenge() {
        setFSMState(STATE.CRES);
    }

    function handleSubmit(cc: CreditCard, ch: CardHolder) {
        setFSMState(STATE.FINGERPRINT);

        let _authHandler = EnrollmentFlowHandlerFactory.create(cc, ch);
        setAuthHandler(_authHandler);

        _authHandler.prepare().then(() => {
            setFSMState(STATE.AREQ);
        }).catch(err => {
            setError(err);
            setFSMState(STATE.INPUT_CARD);
        });
    }

    let currentElem;

    if ( FSMState == STATE.INPUT_CARD ) {
         currentElem = (<div>
        <div className="rationale">
            Enrolling your payment card allows us to provide you with digital receipt services.
                Your transaction details will be instantly shared with X-Receipts and processed for receipt retrieval or card linking in loyalty programs.
        </div>
        {displayErrorMessage()}

         <CNI onSubmit={(cc, ch) => {handleSubmit(cc, ch);}}/>
         <Disclaimer />
         </div>);
    } else if ( FSMState === STATE.FINGERPRINT ) {
        currentElem = (<LoadingSpinner loadingText="Preparing enrollment"/>);
    } else if (FSMState === STATE.AREQ ) {
        currentElem = (<LoadingSpinner loadingText="Preparing to authenticate with your bank"/>);
    } else if (FSMState === STATE.CREQ ) {
        currentElem = (<ACSChallenge key={ACSChallengeKey} acsData={challengeData} callback={() => {postChallenge();}}  />);
    } else if (FSMState === STATE.CRES ) {
        currentElem = (<LoadingSpinner loadingText="Challenge completed. Please wait..."/>);
    } else if ( FSMState === STATE.RRES) {

        console.log(authHandler);
        if ( ! authHandler.isAuthenticated() ) {
            console.log("Authentication failed");
            setError("Authentication failed!");
            setFSMState(STATE.ERROR);
        }
        currentElem = (
        <SuccessBox>
        Thank you. Your card has been successfully enrolled.
        </SuccessBox>

        );
    } else if ( FSMState === STATE.ERROR ) {
        currentElem = (<div><center>{error}</center></div>);
    }

    return (
            <BrowserRouter>
            <div className="App">
            <Header/>


            <Routes>
                <Route path="/" element={currentElem}/>
                <Route path="/consent-flow-ui-callback" element={<div>Finished!</div>}/>
            </Routes>


            </div>
            </BrowserRouter>
           );
}

export default App;
