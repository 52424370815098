export const MC_API_BASE_URL = process.env?.REACT_APP_MC_API_BASE_URL ?? "http://localhost:5000";

export const MC_BASE_URL = process.env?.REACT_APP_MC_BASE_URL ?? "https://sandbox.consents.mastercard.com";

export const VISA_API_BASE_URL = process.env?.REACT_APP_MC_API_BASE_URL ?? "http://localhost:5000";

export const VISA_ENABLED = (() => {

    let value = process.env?.REACT_APP_VISA_ENABLED;

    if ( value && value == "true" ) return true;

    return false;

})();

export const MASTERCARD_ENABLED = (() => {

    let value = process.env?.REACT_APP_MASTERCARD_ENABLED;

    if ( value && value == "true" ) return true;

    return false;

})();

console.log(process.env);



