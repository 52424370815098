import ACSChallengeData from "./ACSChallengeData";
import CardHolder from "./CardHolder";
import { CreditCard } from "./CardNumber";
import {VISA_API_BASE_URL} from "./Config";
import IEnrollmentFlowHandler from "./IEnrollmentFlowHandler";
import RequestFactory from "./RequestFactory";

export default class VisaEnrollmentFlowHandler implements IEnrollmentFlowHandler {

    private readonly creditCard : CreditCard;
    private readonly cardholder : CardHolder;

    private _cardRef: string;

    authenticationSuccess : boolean = false;


    constructor(cc: CreditCard, cardholder: CardHolder) {
        this.creditCard = cc;
        this.cardholder = cardholder;
        this._cardRef = "INVALID";
    }

    getIdentifier(): string {
        return this._cardRef;
    }

    private checkAuthState(json: any) : boolean {
        if ( json?.responseStatus?.code == "SUCCESS" ) return true;
        return false;
    }

    async prepare(): Promise<boolean> {
        let data;

        try {
            data = await VisaEnrollmentAPIHandler.doPreparation(this.creditCard, this.cardholder);
        } catch ( e: any ) {
            return Promise.reject("Failed to enroll");
        }

        let json = await data.json();

        this.authenticationSuccess = this.checkAuthState(json);

        let cardId = json?.userDetails?.cards[0].cardId;

        if ( cardId ) {
            this._cardRef = cardId;
        }




        return Promise.resolve(true);

    }

    async authenticate(): Promise<boolean> {
        return Promise.resolve(true);
    }
    challenge(): ACSChallengeData {
        throw new Error("Method not applicable.");
    }

    needsChallenge() : boolean {
        return false;
    }

    async verify(): Promise<any> {
        return Promise.resolve();
    }

    isAuthenticated(): boolean {
        return this.authenticationSuccess;
    }

}

class VisaEnrollmentAPIHandler {
    static BASE_URL = VISA_API_BASE_URL;

    static async doPreparation(
        cc: CreditCard,
        cardholder: CardHolder
    ) : Promise<Response> {


        let request = RequestFactory.createRequest(
            {
            "card": cc.toJSON(),
            "cardholder": cardholder.toJSON()
            }
        );

        return fetch(this.BASE_URL + "/visa/api/enroll", request);
    }



};
