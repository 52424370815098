import React, { useRef } from 'react';

interface DisclaimerState { };


interface DisclaimerProps { };

export default class Disclaimer extends React.Component<DisclaimerProps, DisclaimerState> {

    constructor(props: DisclaimerProps) {
        super(props);

    }

    render() {
        return (
        <div className="en-Disclaimer">
            By tapping the submit button I agree to connect my card to Receipts.
            <br/> I agree to the Terms & Conditions and Privacy Policy.
        </div> );
    }


}
