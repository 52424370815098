import React, { useRef } from 'react';

interface LoadingSpinnerState {

};


interface LoadingSpinnerProps {
    loadingText: string;
};

export default class LoadingSpinner extends React.Component<LoadingSpinnerProps, LoadingSpinnerState> {


    constructor(props: LoadingSpinnerProps) {
        super(props);
    }

    render() {
        return (
        <div>
         <center>{this.props.loadingText}</center><br/><center><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></center></div>);
    }
}
